import { useState, useEffect } from "react";
import { addDays, format, startOfWeek } from "date-fns";
import { AiOutlineCheckCircle, AiFillCheckCircle } from "react-icons/ai";

import { Match, MatchesData, PartitsProps, groupsMatchesData } from "../types";
import { Loader, Scroller, ScrollerGroups } from ".";
import Buttons from "./Buttons";
import { leaguesFep } from "../federations";
import { getUrl } from "../lib/functions";

const Partits = ({ league, group, setMode }: PartitsProps) => {
	const [matchesData, setMatchesData] = useState<MatchesData>({});
	const [selectedJornada, setSelectedJornada] = useState<string | null>(null);
	const [filteredMatches, setFilteredMatches] = useState<
		{ date: string; matches: Match[] }[]
	>([]);

	const [groups, setGroups] = useState<string[]>([]);
	const [groupSelected, setGroupSelected] = useState<number>(0);
	const [groupsLeagues, setGroupsLeagues] = useState<groupsMatchesData>({});

	const [isClicked, setIsClicked] = useState(false);

	// Function to fetch match data
	const fetchMatchData = async () => {
		try {
			let response;
			if (leaguesFep.some((l) => l.key === league.key)) {
				response = await fetch(getUrl("/api/matches/") + league.key, {
					cache: "no-store",
				});
			} else {
				response = await fetch(getUrl("/api/fecapa/matches/") + league.key, {
					cache: "no-store",
				});
			}

			if (response.ok) {
				let jsonData;
				if (leaguesFep.some((l) => l.key === league.key)) {
					jsonData = await response.json();
					clean_and_sort(jsonData);
				} else {
					let jsonDataRaw = await response.json();

					const groupsKeys = Object.keys(jsonDataRaw);
					setGroups(groupsKeys);

					const groupsLeague: groupsMatchesData = {}; // Use MatchesData type for sortedData

					for (const key of groupsKeys) {
						groupsLeague[key] = jsonDataRaw[key];
					}

					setGroupsLeagues(groupsLeague);
					setMatchesData(Object.values(groupsLeague)[group || 0]);
					console.log(jsonDataRaw);
				}
			} else {
				console.error("Failed to retrieve data:", response.statusText);
			}
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	/// clean and sort data
	const clean_and_sort = (jsonData: MatchesData) => {
		// sort data from jsonify unorder
		const jornadaKeys = Object.keys(jsonData).sort((a, b) => {
			return a.localeCompare(b, undefined, { numeric: true });
		});

		const sortedData: MatchesData = {}; // Use MatchesData type for sortedData

		for (const key of jornadaKeys) {
			sortedData[key] = jsonData[key];
		}
		setMatchesData(sortedData);
	};

	// Call fetchMatchData when the component mounts
	useEffect(() => {
		fetchMatchData();
	}, []);

	useEffect(() => {
		try {
			const currentDateString = getNextSaturday();

			// Iterate through the properties of matchesData (assuming each property is a jornada)
			for (const jornada in matchesData) {
				const matchingMatch = matchesData[jornada].find(
					(match) => match.date === currentDateString
				);

				if (matchingMatch) {
					// If a match is found, set the selected jornada to the corresponding jornada
					setSelectedJornada(jornada);
					return; // Exit the loop when a match is found
				}
			}

			// If no match is found, select the first available Jornada
			const firstJornada = Object.keys(matchesData)[0];
			setSelectedJornada(firstJornada);
		} catch (error) {
			return;
		}
	}, [matchesData]);

	useEffect(() => {
		// Filter matches based on the selected Jornada
		if (selectedJornada) {
			const matchesForSelectedJornada = matchesData[selectedJornada];

			const groupedMatches = groupMatchesByDate(matchesForSelectedJornada);
			setFilteredMatches(groupedMatches);
		}
	}, [selectedJornada, matchesData]);

	const getNextSaturday = () => {
		const currentDate = new Date();
		const weekStartDate = startOfWeek(currentDate, { weekStartsOn: 1 }); // Assuming week starts on Monday (1)
		const saturdayDate = addDays(weekStartDate, 5); // Add 5 days to get to Saturday
		return format(saturdayDate, "dd/MM/yyyy");
	};

	const groupMatchesByDate = (
		matches: Match[]
	): { date: string; matches: Match[] }[] => {
		const groupedMatches: { date: string; matches: Match[] }[] = [];
		matches.forEach((match) => {
			const dateExistsIndex = groupedMatches.findIndex(
				(group) => group.date === match.date
			);
			if (dateExistsIndex !== -1) {
				// Date already exists, push the match to the existing date group
				groupedMatches[dateExistsIndex].matches.push(match);
			} else {
				// Date doesn't exist, create a new date group
				groupedMatches.push({ date: match.date, matches: [match] });
			}
		});
		return groupedMatches;
	};

	return filteredMatches.length <= 0 ? (
		<Loader />
	) : (
		<>
			{setMode && groups.length > 1 && (
				<ScrollerGroups
					groups={groups}
					groupSelected={groupSelected}
					setGroupSelected={setGroupSelected}
					setInfo={setMatchesData}
					groupsLeagues={groupsLeagues}
				/>
			)}

			<button
				className="fixed w-full top-[6.5rem] -mt-9 z-40"
				onClick={() => {
					localStorage.setItem("league", league.name);
					localStorage.setItem("group", groupSelected.toString());
					setIsClicked(true);
				}}
			>
				{(localStorage.getItem("league") == league.name &&
					localStorage.getItem("group") == groupSelected.toString()) ||
				isClicked ? (
					<AiFillCheckCircle size={20} className="w-full max-width" />
				) : (
					<AiOutlineCheckCircle size={20} className="w-full max-width" />
				)}
			</button>

			{setMode && (
				<Scroller
					matchesData={matchesData}
					setSelectedJornada={setSelectedJornada}
					selectedJornada={selectedJornada}
				/>
			)}

			<div className="flex flex-col items-center mt-2">
				<h1 className="text-gray-100 font-semibold mb-1">PARTITS</h1>
				<div
					className={`mx-2 bg-gray-100 opacity-95 p-1 rounded-lg min-w-[310px]`}
				>
					{filteredMatches.map((dateGroup, dateIndex) => (
						<div key={dateIndex}>
							<div className="flex justify-center mt-5 font-bold">
								{dateGroup.date}
							</div>
							{dateGroup.matches.map((match, matchIndex) => (
								<div
									key={matchIndex}
									className="flex items-center text-center text-xs justify-evenly py-2 border-b border-gray-300"
								>
									<p className="w-[30%] font-medium">{match.team1}</p>
									<img
										src={match.team1_logo}
										alt={match.team1}
										className="w-4 h-full"
									/>
									<p
										className={`text-base ${
											match.score === "-" ? "text-slate-500" : "font-bold"
										}`}
									>
										{match.score === "-"
											? match.time
											: match.score.replace("-", " - ")}
									</p>
									<img
										src={match.team2_logo}
										alt={match.team2}
										className="w-5 h-full"
									/>
									<p className="w-[30%] font-medium">{match.team2}</p>
								</div>
							))}
						</div>
					))}
				</div>
				{setMode && (
					<Buttons
						setMode={setMode}
						state1="classi"
						state2="stats"
						text1="Classificació"
						text2="Estadístiques"
					/>
				)}
			</div>
		</>
	);
};

export default Partits;
