import React from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { Links } from "../components";

interface Props {}

const AboutUs = (props: Props) => {
	return (
		<>
			<div className="absolute right-5 top-5 z-40 text-black mb-20">
				<Link to={"/"}>
					<RxCross2 size={30} />
				</Link>
			</div>
			<div className="mt-16 mx-8">
				<div className="text-3xl font-semibold">Sobre Nosaltres</div>
				<p className="text-slate-700 mt-4 text-lg mb-20">
					Benvinguts a la nostra plataforma d'hoquei! Som apassionats
					pel món de l'hoquei patins a Catalunya i Espanya. La nostra
					missió és proporcionar-vos una font completa d'informació
					sobre les lligues d'hoquei patins, incloent els resultats
					dels partits, les estadístiques i les classificacions dels
					equips. Ja siguis jugador, seguidor o simplement tinguis
					curiositat sobre aquest emocionant esport, has arribat al
					lloc adequat. Estem dedicats a mantenir-vos informat i
					involucrats en el món de l'hoquei patins. Si teniu alguna
					pregunta o voleu posar-vos en contacte amb nosaltres, no
					dubteu a fer-ho. Ens encanta escoltar els nostres usuaris i
					valorar els vostres comentaris. Gràcies per unir-vos a
					aquesta aventura de l'hoquei patins!
				</p>
			</div>
			<div className="fixed bottom-0 w-full">
				<Links />
			</div>
		</>
	);
};

export default AboutUs;
