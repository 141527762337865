import { useEffect, useState } from "react";

import { Competicio, Header, Leagues, Config, LandingPage } from "../components";
import { leaguesFep, leaguesFecapa } from "../federations";

import { LeagueProps } from "../types";
import { hoqueiImage } from "../images";

import { IoIosCodeWorking } from "react-icons/io";

export default function Home() {
	const leagueStg = localStorage.getItem("league") || leaguesFep[0].name;

	let league;
	league = leaguesFep.find((league) => league.name === leagueStg);
	if (!league)
		league = leaguesFecapa.find((league) => league.name === leagueStg);
	if (!league) league = leaguesFep[0];

	let group;
	let groupStr = localStorage.getItem("group");
	if (groupStr) group = parseInt(groupStr) + 0;

	const [leagueSelected, setLeagueSelected] = useState<LeagueProps>(
		league || leaguesFep[0]
	);
	const [chosen, setChosen] = useState(false);
	const [isHowTo, setIsHowTo] = useState(false);
	const [isLeagues, setIsLeagues] = useState(false);

	return false ? (
		<div className="max-w-screen-lg m-auto">
			<img
				src={hoqueiImage}
				alt="hoquei"
				className="fixed h-full w-full object-cover max-width object-right brightness-50 -z-10"
			/>
			<div className="flex h-screen p-10 items-center justify-center text-center font-semibold">
				<p className="bg-gray-100 p-10 rounded-lg opacity-90 flex flex-col items-center">
					<IoIosCodeWorking size={40} className="mb-3 align-center"/>
					La pàgina està en manteniment degut a problemes amb el
					servidor, tornarà a estar activa en els pròxims dies.
					<br />
					<br />
					Gràcies per la vostra paciència
				</p>
			</div>
		</div>
	) : (
		<div className="max-w-screen-lg m-auto">
			<img
				src={hoqueiImage}
				alt="hoquei"
				className="fixed h-full w-full object-cover max-width object-right brightness-50 -z-10"
			/>

			<Header
				name={leagueSelected.name}
				setIsLeagues={setIsLeagues}
				chosen={chosen}
				setChosen={setChosen}
			/>
			<div className="pt-24 mb-10">
				{isLeagues && (
					<Leagues
						setLeagueSelected={setLeagueSelected}
						setIsLeagues={setIsLeagues}
						setChosen={setChosen}
					/>
				)}
				{!chosen
					? !isLeagues && (
							<LandingPage league={league} group={group} />
					  )
					: !isLeagues && <Competicio league={leagueSelected} />}
			</div>
		</div>
	);
}
