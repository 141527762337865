import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { Links } from "../components";

interface Props {}

const ContactUs = (props: Props) => {
	return (
		<>
			<div className="absolute right-5 top-5 z-40 text-black mb-20">
				<Link to={"/"}>
					<RxCross2 size={30} />
				</Link>
			</div>
			<div className="mt-16 mx-8 mb-20">
				<div className="text-3xl font-semibold">Contacte</div>
				<p className="text-slate-700 mt-4 text-lg">
					Estem aquí per ajudar-vos i escoltar-vos. Si teniu qualsevol pregunta,
					comentari o suggeriment, si us plau, poseu-vos en contacte amb
					nosaltres. El nostre equip està preparat per ajudar-vos amb qualsevol
					consulta relacionada amb la nostra plataforma, el seu contingut o
					qualsevol altre assumpte. Podeu posar-vos en contacte amb nosaltres
					per correu electrònic a contact@ericterrisse.com. Tenim com a objectiu
					respondre a les vostres consultes el més ràpid possible i estem
					agraïts per la vostra participació a la nostra plataforma.
				</p>
			</div>
			<div className="fixed bottom-0 w-full">
				<Links />
			</div>
		</>
	);
};

export default ContactUs;
