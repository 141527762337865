import { useState } from 'react'

import { LandingProps } from '../types'
import { LeaguesLanding } from '.';
import Buttons from './Buttons';
import { leaguesFecapa, leaguesFep } from '../federations';

const Leagues = ({ setLeagueSelected, setIsLeagues, setChosen }: LandingProps) => {

    const [comp, setComp] = useState<string>("fep")

    return (
        <div className='flex flex-col mt-10'>
            <div className='self-center'>
                <Buttons setMode={setComp} state1={"fep"} state2={"fecapa"} text1={'FEP'} text2={'FECAPA'} topRounded={true} />
            </div>
            {comp == "fep" ? (
                <div className='mb-20'>
                    <LeaguesLanding league={leaguesFep} setLeagueSelected={setLeagueSelected} setIsLeagues={setIsLeagues} setChosen={setChosen} />
                </div>
            ) : (
                <div className='mb-20'>
                    <LeaguesLanding league={leaguesFecapa} setLeagueSelected={setLeagueSelected} setIsLeagues={setIsLeagues} setChosen={setChosen} />
                </div>
            )}
        </div>
    )
}

export default Leagues