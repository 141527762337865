import { useState } from 'react';

import { PropsMatches } from '../types';
import { Classificacio, Header, Partits } from '.';
import Statistics from './Statistics';

const Competicio = ({ league }: PropsMatches) => {

    const [mode, setMode] = useState<string>("partits");

    return (
        <>
            {mode == "partits" && <Partits league={league} setMode={setMode} />}
            {mode == "classi" && <Classificacio league={league} setMode={setMode} />}
            {mode == "stats" && <Statistics league={league} setMode={setMode} />}
        </>
    );
}

export default Competicio