import { PlayerStatsProps, StatsProps } from "../types";
import { useEffect, useState } from "react";
// import { LandingAd, Loader } from '.';
import Buttons from "./Buttons";
import { Loader } from ".";
import { getUrl } from "../lib/functions";

const Statistics = ({ league, setMode }: StatsProps) => {
	const [playersStats, setPlayersStats] = useState<PlayerStatsProps | null>(
		null
	);

	function sortDataByGoals(data: PlayerStatsProps): PlayerStatsProps {
		// Convert the object into an array of key-value pairs
		const dataArray = Object.entries(data);

		// Sort the array based on the "pos" values
		dataArray.sort(
			([, playerA], [, playerB]) =>
				parseInt(playerB.goals) - parseInt(playerA.goals)
		);

		// Convert the sorted array back into an object
		const sortedData: PlayerStatsProps = {};
		dataArray.forEach(([playerName, playerStats]) => {
			sortedData[playerName] = playerStats;
		});

		return sortedData;
	}

	const fetchPlayerStats = async () => {
		try {
			const response = await fetch(getUrl("/api/stats/") + league.key, {
				cache: "no-store",
			});

			if (response.ok) {
				const data = await response.json();

				if (data) {
					const sortedData = sortDataByGoals(data);
					setPlayersStats(sortedData);
				}
			} else {
				console.error("Failed to retrieve player stats:", response.statusText);
			}
		} catch (error) {
			console.error("An error occurred while fetching player stats:", error);
		}
	};

	// Call fetchClassiData and fetchPlayerStats when the component mounts
	useEffect(() => {
		fetchPlayerStats();
	}, []);

	return !playersStats ? (
		<Loader
			missatge="Les estadístiques d'una lliga es començaran a mostrar un
                                cop començades les primeres jornades de tots els grups de la mateixa"
		/>
	) : (
		<div className="flex flex-col mt-5">
			<h1 className="self-center text-gray-100 font-semibold mb-1">
				ESTADÍSTIQUES
			</h1>
			<div className="self-center">
				<Buttons
					setMode={setMode}
					state1="classi"
					state2="partits"
					text1="Classificació"
					text2="Partits"
					topRounded={true}
				/>
			</div>
			<div className="flex justify-center bg-gray-100 opacity-95 mx-2 rounded-lg pb-5">
				<div className="flex flex-col gap-3 mt-2 mb-18">
					<div className="flex font-semibold gap-1 justify-end text-center">
						<div className="w-10">G</div>
						<div className="w-10">A</div>
						<div className="w-10">P</div>
						<div className="w-10">D</div>
					</div>
					{Object.entries(playersStats).map(([playerName, stats], index) => (
						<>
							<div
								className="flex justify-between items-center"
								key={playerName}
							>
								<div className="flex w-[10rem] md:w-[12rem] justify-around gap-3 md:gap-6 items-center">
									<div className="flex gap-2 items-center">
										<img
											src={stats["Team Logo URL"]}
											alt={playerName}
											className="w-5 h-full"
										/>
										<div className="text-xs w-[8rem]">{playerName}</div>
									</div>
								</div>
								<div className="flex gap-1 text-center">
									<div className="w-10 font-semibold">{stats.goals}</div>
									<div className="w-10">{stats.as}</div>
									<div className="w-10">{stats.pen}</div>
									<div className="w-10">{stats.FD}</div>
								</div>
							</div>
						</>
					))}
				</div>
			</div>
		</div>
	);
};

export default Statistics;
