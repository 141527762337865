import { FiSettings } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";

import { HeaderProps } from "../types";
import { Link } from "react-router-dom";

const Header = ({
	name,
	setLeagueSelected,
	setIsLeagues,
	chosen,
	setChosen,
}: HeaderProps) => {
	return (
		<header
			className={`fixed w-screen h-24 max-w-screen-lg top-0 z-10 flex items-center opacity-95 bg-gray-100 ${
				!setLeagueSelected && "justify-center"
			}`}
		>
			<div className="flex w-full justify-between items-center mx-10">
				<div className="z-40 text-black">
					<Link to={"/config"}>
						<FiSettings size={25} />
					</Link>
				</div>
				<div className="flex align-center justify-center py-8 gap-3">
					<p
						className="font-bold capitalize text-lg truncate"
						onClick={() => setChosen(true)}
					>
						{name}
					</p>
				</div>
				<div className="text-black">
					<AiOutlineSearch
						size={25}
						onClick={() => {
							setIsLeagues(true);
							setChosen(false);
						}}
					/>
				</div>
			</div>
		</header>
	);
};

export default Header;
