import React from "react";
import { Link } from "react-router-dom";

interface Props {}

const Links = (props: Props) => {
	return (
		<div className="w-full text-slate-700 flex justify-around text-sm items-center text-center pb-3 pt-1 bg-slate-100">
			<Link to={"/aboutus"}>Sobre Nosaltres</Link>
			<Link to={"/privacypolicy"}>Polítiques de Privacitat</Link>
			<Link to={"/contactus"}>Contacte</Link>
			<Link to={"/termsconditions"}>Termes i condicions</Link>
		</div>
	);
};

export default Links;
