import { ScrollerProps } from '../types';
import { useMemo } from 'react';

const Scroller = ({ matchesData, setSelectedJornada, selectedJornada }: ScrollerProps) => {
    // Sort the keys (matchdays) in numeric order
    const sortedJornadas = useMemo(() => {
        return Object.keys(matchesData).sort((a, b) => {
            return a.localeCompare(b, undefined, { numeric: true });
        });
    }, [matchesData]);

    return (
        <div className="flex overflow-x-auto opacity-80">
            {sortedJornadas.map((jornada, index) => (
                <button
                    key={jornada}
                    onClick={() => setSelectedJornada(jornada)}
                    className={`p-8 mx-1 my-7 rounded-full text-black-100 text-lg font-bold flex w-10 h-10 items-center justify-center
                          ${selectedJornada === jornada ? 'bg-red-500 text-white' : 'bg-gray-100'}`}
                >
                    J{index + 1}
                </button>
            ))}
        </div>
    );
}

export default Scroller;
