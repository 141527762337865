import { ButtonProps } from '../types'

const Buttons = ({ setMode, state1, state2, text1, text2, topRounded }: ButtonProps) => {
    return (
        <div className='flex gap-4'>
            <button
                onClick={() => setMode(state1)}
                className={`bg-gray-100 opacity-95 min-w-[130px] font-semibold px-4 py-4  text-md sm:text-lg ${topRounded ? "rounded-t-lg" : "rounded-b-lg"}`}
            >
                {text1}
            </button>
            <button
                onClick={() => setMode(state2)}
                className={`bg-gray-100 opacity-95 min-w-[130px] font-semibold px-4 py-4  text-md sm:text-lg ${topRounded ? "rounded-t-lg" : "rounded-b-lg"}`}
            >
                {text2}
            </button>
        </div>
    )
}

export default Buttons