import { ClassiFecapaProps, LeagueClassiProps, ScrollerGroupsProps, groupsMatchesData } from '../types';

const Scroller = ({ groups, groupSelected, setGroupSelected, setInfo, groupsLeagues }: ScrollerGroupsProps) => {

    // Custom sorting function
    function customSort(a: string, b: string): number {
        try {
            // Extract the numeric part from the strings
            const numA: number = parseInt(a.match(/\d+/)![0]);
            const numB: number = parseInt(b.match(/\d+/)![0]);

            // Compare the numeric parts
            if (numA < numB) {
                return -1;
            } else if (numA > numB) {
                return 1;
            } else {
                // If the numeric parts are equal, compare the entire strings
                return a.localeCompare(b);
            }
        } catch (error) {
            console.log(error)
            return 0;
        }
    }

    function sortDataByPos(data: LeagueClassiProps): LeagueClassiProps {
        // Convert the object into an array of key-value pairs
        const dataArray = Object.entries(data);

        // Sort the array based on the "pos" values
        dataArray.sort(([, teamA], [, teamB]) => parseInt(teamA.pos) - parseInt(teamB.pos));

        // Convert the sorted array back into an object
        const sortedData: LeagueClassiProps = {};
        dataArray.forEach(([teamName, teamStats]) => {
            sortedData[teamName] = teamStats;
        });

        return sortedData;
    }

    const sortGroups = (groupsLeagues: groupsMatchesData | ClassiFecapaProps) => {
        let sortedData: groupsMatchesData | ClassiFecapaProps = {};
        sortedGroups.forEach((groupName) => {
            const teamStats = groupsLeagues[groupName];
            if (teamStats) {
                sortedData[groupName] = teamStats;
            }
        });
        return sortedData;
    }

    // Sort the array using the custom sorting function
    const sortedGroups: string[] = [...groups].sort(customSort);

    return (
        <div className="flex overflow-x-auto bg-gray-100 max-w-md left-0 right-0 m-auto">
            {sortedGroups.map((name, index) => (
                <button
                    key={index}
                    onClick={() => {
                        setGroupSelected(index);
                        setInfo(sortDataByPos(Object.values(sortGroups(groupsLeagues))[index]));
                    }}
                    className={`lowercase px-5 py-1 mx-1 text-xs text-black-100 font-bold flex w-full h-auto items-center justify-center
                                ${groupSelected === index ? 'bg-red-500 text-white' : 'bg-gray-100'}`}
                >
                    {name}
                </button>
            ))}
        </div>
    );
}

export default Scroller;
