import React from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { Links } from "../components";

interface Props {}

const TermsConditions = (props: Props) => {
	return (
		<>
			<div className="absolute right-5 top-5 z-40 text-black">
				<Link to={"/"}>
					<RxCross2 size={30} />
				</Link>
			</div>
			<div className="mt-16 mx-8">
				<div className="text-3xl font-semibold">
					Termes i Condicions
				</div>
				<p className="text-slate-700 mt-4 text-lg mb-20">
					Abans de fer servir la nostra plataforma, és important
					entendre les normes i les directrius que regeixen el vostre
					accés i les interaccions amb els nostres serveis. Els
					nostres Termes i Condicions estableixen les vostres
					responsabilitats com a usuari, així com les nostres
					obligacions envers vosaltres. En fer servir el nostre lloc
					web i aplicació, accepteu complir amb aquests termes, que
					inclouen normes relacionades amb l'ús del contingut, els
					drets de propietat intel·lectual i la conducta acceptable.
					Si us plau, reviseu detingudament els nostres Termes i
					Condicions per garantir una experiència positiva i
					respectuosa per a tots els usuaris. Si teniu alguna pregunta
					o preocupació en relació amb els nostres Termes i
					Condicions, si us plau, poseu-vos en contacte amb nosaltres.
					La vostra conformitat amb aquests termes és molt apreciada i
					contribueix al benestar de la nostra comunitat.
				</p>
			</div>
			<div className="fixed bottom-0 w-full">
				<Links />
			</div>
		</>
	);
};

export default TermsConditions;
