import { FederationProps } from '../types'
import { fecapaLogo } from "../images"

const Fep = ({ league, setLeagueSelected, setIsLeagues, setChosen }: FederationProps) => {
    return (
        <div className='flex flex-col justify-center bg-gray-100 opacity-95 mx-5 rounded-lg'>
            {league.map((league, index) => (
                <button
                    key={index}
                    className='flex items-center px-5 py-3 gap-5 uppercase font-medium rounded-lg'
                    onClick={() => {
                        setLeagueSelected(league)
                        setIsLeagues(false)
                        setChosen(true)
                    }}
                >
                    <img src={league.image || fecapaLogo} width={50} alt={league.name} />
                    <p className='text-left'>{league.name}</p>
                </button>
            ))}
        </div>
    )
}

export default Fep