import { LeagueProps } from "../types"
import Classificacio from "./Classificacio";
import Competicio from "./Competicio"
import Partits from "./Partits"

interface Props {
  league: LeagueProps;
  group?: number;
}

const LandingPage = ({ league, group }: Props) => {
  return (
      <div>
        <Partits league={league} group={group} />
        <Classificacio league={league} group={group} />
      </div>
  )
}

export default LandingPage