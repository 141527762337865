import React from "react";
import { RxCross2 } from "react-icons/rx";
import { IoShareOutline } from "react-icons/io5";
import { phoneImg } from "../images";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Links } from ".";

interface HowToProps {
	setIsHowTo: React.Dispatch<React.SetStateAction<boolean>>;
}

const Config = () => {
	return (
		<div className="flex flex-col items-center left-5 right-5 top-5 bottom-5 z-50 bg-slate-100 opacity-97 rounded-lg overflow-auto">
			<div className="absolute right-5 top-5 z-40 text-black">
				<Link to={"/"}>
					<RxCross2 size={30} />
				</Link>
			</div>
			<h1 className="text-black mt-16 text-lg font-bold text-center mx-5">
				Afegir categoria i grup a la pantalla principal
			</h1>
			<h1 className="text-center m-5">
				Dirigeix-te a la secció partits de la categoria i grup (si
				aplica) que prefereixis i prem el boto{" "}
				<AiOutlineCheckCircle size={20} className="w-full" />
			</h1>

			<h1 className="text-black mt-8 text-lg font-bold">
				Com afegir l'app a l'escriptori
			</h1>
			<h1 className="text-black mt-5 text-md flex flex-col items-center">
				1. Prem el botó de compartir <IoShareOutline size={20} /> a baix
				al centre de la pantalla
			</h1>
			<h1 className="text-black mt-5 text-md flex flex-col items-center max-w-lg">
				2.{" "}
				<img
					src={phoneImg}
					alt="phoneImg"
					className="w-full p-10 pt-5 h-auto"
				/>
			</h1>
			<Links />
		</div>
	);
};

export default Config;
