import { hoquei } from '../images'
// import LandingAd from './LandingAd'

interface LoaderProps {
    missatge?: string;
}

const Loader = ({ missatge }: LoaderProps) => {
    return (
        <div className='flex flex-col items-center justify-center mt-20 w-full gap-3'>
            <div className='loader'><img src={hoquei} alt="hoquei" className='moving-image' /></div>
            {missatge && <h1 className='font-semibold m-5 text-center bg-gray-100 p-3 rounded-lg opacity-80'>{missatge}</h1>}
        </div>
    )
}

export default Loader