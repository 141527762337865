import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { Config } from "./components";
import AboutUs from "./pages/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import ContactUs from "./pages/ContactUs";

export default function App() {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/config" element={<Config />} />
			<Route path="/aboutus" element={<AboutUs />} />
			<Route path="/privacypolicy" element={<PrivacyPolicy />} />
			<Route path="/contactus" element={<ContactUs />} />
			<Route path="/termsconditions" element={<TermsConditions />} />
		</Routes>
	);
}
