import { LeagueProps } from "../types";

import {
	parlemoklliga,
	oklligabronzenord,
	oklligabronzesud,
	oklligaiberdrola,
	oklligaplatafemenina,
	oklligaplatanord,
	oklligaplatasud,
} from "../images";

export const leaguesFep: LeagueProps[] = [
	{ key: 2477, name: "parlem ok lliga", image: parlemoklliga },
	{ key: 2478, name: "ok lliga iberdrola", image: oklligaiberdrola },
	{ key: 2503, name: "ok lliga plata femenina", image: oklligaplatafemenina },
	{ key: 2482, name: "ok lliga plata nord", image: oklligaplatanord },
	{ key: 2483, name: "ok lliga plata sud", image: oklligaplatasud },
	{ key: 2480, name: "ok lliga bronze nord", image: oklligabronzenord },
	{ key: 2481, name: "ok lliga bronze sud", image: oklligabronzesud },
];

export const leaguesFecapa: LeagueProps[] = [
	{ key: 2214, name: "nacional catalana masculí" },
	{ key: 2215, name: "nacional catalana femení" },
	{ key: 2216, name: "primera catalana masculina" },
	{ key: 2219, name: "primera catalana femenina" },
	{ key: 2217, name: "segona catalana masculina" },
	{ key: 2220, name: "segona catalana femenina" },
	{ key: 2218, name: "tercera catalana masculina" },

	{ key: 2408, name: "lliga catalana de veterans" },
	{ key: 2517, name: "master 50 veterans" },

	{ key: 2222, name: "junior or preferent" },
	{ key: 2223, name: "junior plata copa" },
	{ key: 2224, name: "juvenil or preferent" },
	{ key: 2225, name: "juvenil plata copa" },

	{ key: 2226, name: "infantil or preferent" },
	{ key: 2227, name: "infantil plata" },
	{ key: 2228, name: "aleví or preferent" },
	{ key: 2229, name: "aleví plata copa" },

	{ key: 2230, name: "fem 17" },
	{ key: 2231, name: "fem 15" },
	{ key: 2232, name: "fem 13 or" },
	{ key: 2233, name: "fem 13 plata" },
	{ key: 2234, name: "fem 11" },
	{ key: 2235, name: "minifem" },

	{ key: 2767, name: "benjamí or bcn preferent 2a" },
	{ key: 2772, name: "benjamí c. barcelona 2a fase" },
	{ key: 2781, name: "benjamí c. federació 2a fase" },
	{ key: 2241, name: "benjamí or girona" },
	{ key: 2242, name: "benjamí plata girona" },
	{ key: 2247, name: "2a fase benjamí or tarragona" },
	{ key: 2252, name: "2a fase benjamí plata tarragona" },
	{ key: 2245, name: "benjamí lleida" },

	{ key: 2580, name: "prebenjamí or bcn 2a fase" },
	{ key: 2584, name: "prebenjamí plata bcn 2a fase" },
	{ key: 2594, name: "prebenjamí bronze bcn 2a fase" },
	{ key: 2243, name: "prebenjamí or girona" },
	{ key: 2244, name: "prebenjamí plata girona" },
	{ key: 2246, name: "prebenjamí lleida" },
	{ key: 2249, name: "2a fase prebenjamí or tarragona" },
	{ key: 2250, name: "2a fase prebenjamí plata tarrag" },
	{ key: 2251, name: "2a fase prebenjamí bronze tarrag" },
];

// { key: 2236, name: "benjamí or barcelona" },
// { key: 2237, name: "benjamí plata barcelona" },

// { key: 2238, name: "prebenjamí or barcelona" },
// { key: 2239, name: "prebenjamí plata barcelona" },
// { key: 2240, name: "prebenjamí bronze barcelona" },
