import { useEffect, useState } from "react";
import { ClassiFecapaProps, ClassiProps, LeagueClassiProps } from "../types";
import { Loader, ScrollerGroups } from ".";
import Buttons from "./Buttons";
import { leaguesFep } from "../federations";
import { getUrl } from "../lib/functions";

const Classificacio = ({ league, group, setMode }: ClassiProps) => {
	const [classiData, setClassiData] = useState<LeagueClassiProps | null>(null);

	const [groups, setGroups] = useState<string[]>([]);
	const [groupSelected, setGroupSelected] = useState<number>(0);
	const [groupsLeagues, setGroupsLeagues] = useState<ClassiFecapaProps>({});

	function sortDataByPos(data: LeagueClassiProps): LeagueClassiProps {
		// Convert the object into an array of key-value pairs
		const dataArray = Object.entries(data);

		// Sort the array based on the "pos" values
		dataArray.sort(
			([, teamA], [, teamB]) => parseInt(teamA.pos) - parseInt(teamB.pos)
		);

		// Convert the sorted array back into an object
		const sortedData: LeagueClassiProps = {};
		dataArray.forEach(([teamName, teamStats]) => {
			sortedData[teamName] = teamStats;
		});

		return sortedData;
	}

	const fetchClassiData = async () => {
		try {
			let response: any;
			if (leaguesFep.some((l) => l.key === league.key)) {
				response = await fetch(getUrl("/api/classis/") + league.key, {
					cache: "no-store",
				});
				if (response.ok) {
					const data = await response.json();

					if (data) {
						const sortedData = sortDataByPos(data);
						setClassiData(sortedData);
					} else {
						if (response)
							console.error("Failed to retrieve data:", response.statusText);
					}
				}
			} else {
				response = await fetch(getUrl("/api/fecapa/classis/") + league.key, {
					cache: "no-store",
				});
				if (response.ok) {
					const data: ClassiFecapaProps = await response.json();
					if (data) {
						setGroups(Object.keys(data));
						setGroupsLeagues(data);
						const initialGroup = Object.values(data)[group || 0];
						const initialData = sortDataByPos(initialGroup);
						setClassiData(initialData);
					}
				}
			}
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	// Call fetchMatchData when the component mounts
	useEffect(() => {
		fetchClassiData();
	}, []);

	useEffect(() => {
		if (classiData) sortDataByPos(classiData);
	}, [classiData]);

	return !classiData ? (
		<Loader />
	) : (
		<>
			{setMode && groups.length > 2 && (
				<ScrollerGroups
					groups={groups}
					groupSelected={groupSelected}
					setGroupSelected={setGroupSelected}
					setInfo={setClassiData}
					groupsLeagues={groupsLeagues}
				/>
			)}
			<div className="flex flex-col mt-5">
				<h1 className="self-center text-gray-100 font-semibold mb-1">
					CLASSIFICACIÓ
				</h1>
				<div className="bg-gray-100 opacity-95 mx-2 rounded-lg">
					<div className={`flex m-5 overflow-x-auto justify-center`}>
						<div className="flex flex-col gap-3 mt-2 mb-18">
							<div className="flex font-semibold gap-[2.3rem]">
								<div className="flex sticky w-[13rem] justify-around gap-3 left-0 items-center bg-gray-100 mr-2" />
								<div className="flex gap-9 items-center ml-[15rem] sm:ml-0">
									<div>PJ</div>
									<div>PG</div>
									<div>PE</div>
									<div>PP</div>
									<div>GF</div>
									<div>GC</div>
									<div>GAV</div>
								</div>
							</div>
							{Object.entries(classiData).map(([team, stats]) => (
								<div className="flex gap-12 items-center" key={team}>
									<div className="flex sticky w-[13rem] justify-around gap-3 left-0 items-center bg-gray-100">
										<div className="flex justify-center">{stats.pos}</div>
										<div className="flex gap-2">
											<img
												src={stats.image}
												alt={team}
												className="w-5 h-full"
											/>
											<div className="text-xs w-[8rem]">{team}</div>
										</div>
										<div className="font-semibold">{stats.punts}</div>
									</div>
									<div className="flex gap-12 items-center ml-[15rem] sm:ml-0">
										<div>{stats.pj}</div>
										<div>{stats.pg}</div>
										<div>{stats.pe}</div>
										<div>{stats.pp}</div>
										<div>{stats.gf}</div>
										<div>{stats.gc}</div>
										<div>{stats.gav}</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				{setMode && (
					<div className="self-center">
						<Buttons
							setMode={setMode}
							state1="stats"
							state2="partits"
							text1="Estadístiques"
							text2="Partits"
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default Classificacio;
